var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pre_sheet_add"},[_c('el-card',{staticStyle:{"min-height":"calc(100vh - 160px)"}},[_c('div',{staticClass:"header",attrs:{"slot":"header"},slot:"header"},[_vm._v("常用报关行")]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],ref:"preSheetDrag",staticStyle:{"width":"calc(100% - 40px)"},attrs:{"data":_vm.preSheetList,"row-key":"agentCode","border":"","fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"type":"index","class-name":"move","width":"50","label":"序号","align":"center"}}),_c('el-table-column',{attrs:{"width":"150","align":"center","class-name":_vm.isEdit ? "disable" : "move"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.edit)?[_c('el-input',{staticClass:"edit-input",attrs:{"size":"small","maxlength":"6","show-word-limit":""},model:{value:(row.shortName),callback:function ($$v) {_vm.$set(row, "shortName", $$v)},expression:"row.shortName"}})]:_c('span',[_vm._v(_vm._s(row.shortName))])]}}])},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" 报关抬头简称 "),_c('el-tooltip',{attrs:{"placement":"top","effect":"light","content":"显示在菜单栏，最多不能超过6个中文"}},[_c('div',{staticStyle:{"whitespace":"normal","color":"#333"}},[_c('i',{staticClass:"el-icon-warning",staticStyle:{"color":"#ccc","margin-left":"5px"}})])])],1)])],2),_c('el-table-column',{attrs:{"label":"企业全称","prop":"company","align":"center","class-name":_vm.isEdit ? "disable" : "move"}}),_c('el-table-column',{attrs:{"width":"200","label":"十位编码","prop":"agentCode","align":"center","class-name":_vm.isEdit ? "disable" : "move"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.edit)?[_c('el-input',{staticClass:"edit-input",attrs:{"size":"small","maxlength":"10","show-word-limit":""},model:{value:(row.agentCode),callback:function ($$v) {_vm.$set(row, "agentCode", $$v)},expression:"row.agentCode"}})]:_c('span',[_vm._v(_vm._s(row.agentCode))])]}}])}),_c('el-table-column',{attrs:{"align":"center","class-name":_vm.isEdit ? "disable" : "move"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.edit)?_c('el-button',{attrs:{"plain":"","type":"success","size":"small","round":"","icon":"el-icon-check","loading":_vm.submitLoading},on:{"click":function($event){return _vm.confirmEdit(row, $index)}}},[_vm._v(" 确定 ")]):_c('el-button',{attrs:{"plain":"","type":"primary","round":"","size":"small","icon":"el-icon-edit"},on:{"click":function($event){return _vm.handleEdit(row, $index)}}},[_vm._v(" 编辑 ")]),_c('el-popconfirm',{attrs:{"confirmButtonText":"确定","cancelButtonText":"取消","icon":"el-icon-info","iconColor":"red","title":"确定删除这条报关行数据吗？"},on:{"confirm":function($event){return _vm.delLine($index)}}},[_c('el-button',{staticStyle:{"margin-left":"5px"},attrs:{"slot":"reference","type":"danger","plain":"","round":"","size":"small","icon":"el-icon-delete"},slot:"reference"},[_vm._v(" 删除 ")])],1)]}}])},[_c('template',{slot:"header"},[_c('el-button',{attrs:{"type":"primary","plain":"","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addLine()}}},[_vm._v(" 新增 ")])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }