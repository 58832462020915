<!--  -->
<template>
    <div class="pre_sheet_add">
        <el-card style="min-height: calc(100vh - 160px)">
            <div slot="header" class="header">常用报关行</div>
            <el-table
                ref="preSheetDrag"
                v-loading="listLoading"
                :data="preSheetList"
                row-key="agentCode"
                border
                fit
                highlight-current-row
                style="width: calc(100% - 40px)"
            >
                <el-table-column
                    type="index"
                    class-name="move"
                    width="50"
                    label="序号"
                    align="center"
                ></el-table-column>
                <el-table-column
                    width="150"
                    align="center"
                    :class-name="isEdit ? `disable` : `move`"
                >
                    <template slot="header">
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            报关抬头简称
                            <el-tooltip
                                placement="top"
                                effect="light"
                                content="显示在菜单栏，最多不能超过6个中文"
                            >
                                <div style="whitespace: normal; color: #333">
                                    <i
                                        class="el-icon-warning"
                                        style="color: #ccc; margin-left: 5px"
                                    ></i>
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                    <template slot-scope="{ row }">
                        <template v-if="row.edit">
                            <el-input
                                v-model="row.shortName"
                                class="edit-input"
                                size="small"
                                maxlength="6"
                                show-word-limit
                            />
                        </template>
                        <span v-else>{{ row.shortName }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="企业全称"
                    prop="company"
                    align="center"
                    :class-name="isEdit ? `disable` : `move`"
                ></el-table-column>
                <el-table-column
                    width="200"
                    label="十位编码"
                    prop="agentCode"
                    align="center"
                    :class-name="isEdit ? `disable` : `move`"
                >
                    <template slot-scope="{ row }">
                        <template v-if="row.edit">
                            <el-input
                                v-model="row.agentCode"
                                class="edit-input"
                                size="small"
                                maxlength="10"
                                show-word-limit
                            />
                        </template>
                        <span v-else>{{ row.agentCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    :class-name="isEdit ? `disable` : `move`"
                >
                    <template slot="header">
                        <el-button
                            type="primary"
                            plain
                            size="small"
                            @click="addLine()"
                            icon="el-icon-plus"
                        >
                            新增
                        </el-button>
                    </template>
                    <template slot-scope="{ row, $index }">
                        <el-button
                            v-if="row.edit"
                            plain
                            type="success"
                            size="small"
                            round
                            icon="el-icon-check"
                            :loading="submitLoading"
                            @click="confirmEdit(row, $index)"
                        >
                            确定
                        </el-button>
                        <el-button
                            @click="handleEdit(row, $index)"
                            v-else
                            plain
                            type="primary"
                            round
                            size="small"
                            icon="el-icon-edit"
                        >
                            编辑
                        </el-button>

                        <el-popconfirm
                            confirmButtonText="确定"
                            cancelButtonText="取消"
                            icon="el-icon-info"
                            iconColor="red"
                            @confirm="delLine($index)"
                            title="确定删除这条报关行数据吗？"
                        >
                            <el-button
                                slot="reference"
                                type="danger"
                                plain
                                style="margin-left: 5px"
                                round
                                size="small"
                                icon="el-icon-delete"
                            >
                                删除
                            </el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import {
    getAgentName,
    queryPreSheetByUserId,
    updatePreSheet,
} from "@/api/preSheet";
import Sortable from "sortablejs";
import { preSheetRouter } from "@/utils/permission";
import { mapGetters } from "vuex";
export default {
    components: {},
    data() {
        return {
            preSheetList: [],
            listLoading: false,
            submitLoading: false,
        };
    },

    computed: {
        ...mapGetters(["menu_routes"]),
        isEdit() {
            return this.preSheetList.some((item) => item.edit);
        },
    },
    methods: {
        getData() {
            this.listLoading = true;
            queryPreSheetByUserId()
                .then(({ data }) => {
                    const infoData = data.data;
                    this.$store.commit("info/editPreSheetListState", infoData);
                    this.preSheetList = infoData.map((v) => {
                        this.$set(v, "edit", false);
                        v.originCode = v.agentCode;
                        v.originSName = v.shortName;
                        return v;
                    });
                    this.$nextTick(() => {
                        this.setSort();
                    });
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },

        // 判断是否为编辑，是否存在空数据状态 返回去除当前行数据
        checkStatus(index) {
            const copyPreSheetList = [...this.preSheetList];
            copyPreSheetList.splice(index, 1);
            const isEdit = copyPreSheetList.some((item) => item.edit);
            return { data: copyPreSheetList, isEdit };
        },

        handleEdit(row, index) {
            if (this.checkStatus(index).isEdit) {
                this.$message.warning(
                    "仍有没有确定的数据，请先确定编辑之后再编辑本行",
                );
                return;
            }
            this.preSheetList.splice(index, 1, { ...row, edit: !row.edit });
        },
        confirmEdit(row, index) {
            if (!row.shortName) {
                this.$message.warning("报关抬头简称不能为空");
                row.shortName = row.originSName;
                return;
            }
            if (!row.agentCode) {
                this.$message.warning("十位编码不能为空");
                row.agentCode = row.originCode;
                return;
            }
            // 删掉自己之后再判断是否有相同数据
            if (
                this.checkStatus(index).data.some(
                    (item) => item.agentCode === row.agentCode,
                )
            ) {
                this.$message.warning("当前十位编码已存在，请重新录入");
                return;
            }
            const fitComapyName = localStorage.getItem(`ac_${row.agentCode}`);
            if (fitComapyName) {
                row.edit = false;
                row.originCode = row.agentCode;
                row.company = fitComapyName;
                this.handleMeauList();
                return;
            }
            this.submitLoading = true;
            getAgentName(row.agentCode)
                .then(({ data }) => {
                    // 已全局处理过抛错，这里就不单独处理了
                    row.edit = false;
                    row.company = data.data;
                    localStorage.setItem(`ac_${row.agentCode}`, row.company);
                    row.originCode = row.agentCode;
                    this.handleMeauList();
                })
                .catch(() => {
                    row.agentCode = "";
                })
                .finally(() => {
                    this.submitLoading = false;
                });
        },

        handleMeauList(list = this.preSheetList) {
            const param = list.map(({ agentCode, company, shortName }) => ({
                agentCode,
                company,
                shortName,
            }));
            this.listLoading = true;
            updatePreSheet(param)
                .then(() => {
                    this.$message.success("数据更新完成");
                    this.$store.commit("info/editPreSheetListState", list);
                    this.$nextTick(() => {
                        this.preSheetList = list;
                    });
                    preSheetRouter(list);
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        addLine() {
            if (this.preSheetList && this.preSheetList.length > 9) {
                this.$message.warning("最多可设置10条报关行数据");
                return;
            }
            const isEmpty = this.preSheetList.some(
                (item) => !item.company || !item.agentCode || !item.shortName,
            );
            if (isEmpty) {
                this.$message.warning(
                    "当前还有没有新增完成的报关行数据，请输入完成之后再继续新增",
                );
                return;
            }
            const isEdit = this.preSheetList.some((item) => item.edit);
            if (isEdit) {
                this.$message.warning(
                    "仍有没有确定的数据，请先确定编辑之后再新增数据",
                );
                return;
            }
            this.preSheetList.push({
                company: "",
                // 结合16进制生成唯一值
                id: Math.random().toString(16).slice(2),
                agentCode: "",
                shortName: "",
            });
        },
        delLine(index) {
            this.preSheetList.splice(index, 1);
            this.handleMeauList();
        },
        setSort() {
            if (this.$refs.preSheetDrag) {
                const el = this.$refs.preSheetDrag.$el.querySelectorAll(
                    ".el-table__body-wrapper > table > tbody",
                )[0];
                this.sortable = Sortable.create(el, {
                    ghostClass: "sortable-ghost",
                    setData: function (dataTransfer) {
                        dataTransfer.setData("Text", "");
                    },
                    // 编辑状态下不能拖拽
                    handle: ".move",
                    onEnd: (evt) => {
                        const transData = [...this.preSheetList];
                        const targetRow = transData.splice(evt.oldIndex, 1)[0];
                        transData.splice(evt.newIndex, 0, targetRow);
                        this.handleMeauList(transData);
                    },
                });
            }
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped lang="stylus">
.sortable-ghost {
    opacity: 0.8;
    color: #fff !important;
    background: #42b983 !important;
}
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
    padding: 1.68rem;
}
.header {
    color: white;
    font-size: 1.68rem;
    font-weight: 700;
}
</style>
